<template>
	<template v-if="err">
		<div class="text">Автовизитка еще не зарегистрирована</div>
	</template>
	<template v-else-if="items.length === 0 && loadError">
		<div class="text">Пользователь еще не добавил способов обратной связи</div>
	</template>
	<template v-else>
		<div class="text">Мешает машина?<br />Свяжитесь со мной!</div>
	</template>
	<div class="imgMessage" />
	<template v-if="items && Object.keys(items).length > 0">
		<div class="buttons">
			<a v-for="(item, index) in items" :key="index" :href="item.link">
				<button class="button">{{ item.name }}</button>
			</a>
		</div>
	</template>
	<div class="textDecor">
		<a
			target="_blank"
			:href="platformLink()"
			@click="reachGoal('download_the_app')"
		>
			Создать свою автовизитку
		</a>
	</div>
	<div class="button_logo">
		<a
			target="_blank"
			href="https://cartips.ru/?utm_source=qr&utm_medium=qr_click"
			@click="reachGoal('open_site')"
		>
			<img src="@/assets/images/logo.svg" height="30" width="108" />
		</a>
	</div>
	<div class="download_btn" v-if="showDownloadAndroidBanner()">
		<a
			class="download google_play"
			href="https://play.google.com/store/apps/details?id=ru.cartips.mobile&pcampaignid=web_share"
			target="_blank"
			@click="reachGoal('open_store')"
		></a>
		<a
			class="download ru_store"
			href="https://apps.rustore.ru/app/ru.cartips.mobile?utm_source=qr&utm_medium=qr_clickhttps://apps.rustore.ru/app/ru.cartips.mobile?utm_source=qr&utm_medium=qr_click"
			target="_blank"
			@click="reachGoal('open_store')"
		></a>
	</div>
	<div class="download_btn" v-else>
		<a
			class="download ios"
			href="https://apps.apple.com/ru/app/cartips/id6443699164?utm_source=qr&utm_medium=qr_click"
			@click="reachGoal('open_store')"
		></a>
	</div>
</template>

<script>
import * as utils from '@/utils.js'
import * as ServiceEnricher from '@/serves_detector.js'
import axios from 'axios'

export default {
	name: 'App',
	components: {},
	data: () => ({
		items: [],
		text: '',
		loadError: false,
		err: false,
		metric: false,
	}),
	methods: {
		reachGoal(goal) {
			if (this.metric) {
				this.metric.reachGoal(goal)
			}
		},
		openSite() {
			return 'https://cartips.ru/?utm_source=qr&utm_medium=qr_click'
		},
		platformLink() {
			let os = utils.getOS()
			if (os === utils.androidOS)
				return 'https://play.google.com/store/apps/details?id=ru.cartips.mobile&pcampaignid=web_share'
			if (os === utils.iOS)
				return 'https://apps.apple.com/ru/app/cartips/id6443699164?utm_source=qr&utm_medium=qr_click'
			if (os === utils.OtherOS)
				return 'https://cartips.ru/#get-business-card?utm_source=qr&utm_medium=qr_click'
		},
		showDownloadAndroidBanner() {
			return utils.getOS() === utils.androidOS
		},
		createMetric() {
			var head = document.head || document.getElementsByTagName('head')[0]
			const script = document.createElement('script')

			script.async = true
			script.charset = 'utf-8'
			script.src = 'https://mc.yandex.ru/metrika/tag.js'

			head.appendChild(script)

			script.onload = () => {
				const init = {
					id: '90537117',
					env: 'production',
					// debug: true
				}
				if (typeof Ya !== 'undefined') {
					// eslint-disable-next-line no-undef
					this.metric = new Ya.Metrika2(init)
				}
			}
		},
	},
	async created() {
		try {
			this.createMetric()
			let response = await getData(UidUser())

			if (response.status === 404) {
				this.err = true
				return
			}

			if (response.status !== 200) {
				this.loadError = true
				return
			}

			if (Object.keys(response.data).length === 0) {
				this.loadError = false
				return
			}

			Object.keys(response.data)
				.filter(item => !!response.data[item].user_refer)
				.map(key => {
					let item = response.data[key]

					const button = {}
					button.name = key
					button.link = item.user_refer

					if (button.link.indexOf('https://') === -1) {
						button.link = ServiceEnricher.ServiceEnricher(key, button.link)
					}

					this.items.push(button)
				})
		} catch (error) {
			console.error('Ошибка при получении данных:', error)
			this.loadError = true
		}
	},
}

function UidUser() {
	let url = window.location.href
	let urlParts = url.split('/')
	let uid = urlParts[urlParts.length - 1]
	return uid
}

function getData(UidUser) {
	return axios
		.get(`https://api.cartips.ru/api/v1/qr/feedback/${UidUser}`)
		.catch(error => {
			if (error.response) {
				return {
					status: 404,
				}
			}
		})
}
</script>

<style>
@import '@/assets/style.css';

#app {
	font-family: 'Manrope', sans-serif;
	text-align: center;
	color: #000;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	height: calc(100vh - 40px);
	padding: 20px 16px 20px 16px;
}
</style>
