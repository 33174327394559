export const androidOS = "Android"
export const iOS = "iOS"
export const OtherOS = "Other"


export function getOS() { 
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return androidOS
    }
    
    if (/iPad|iPhone|iPod/.test(ua)) {
        return iOS 
    }
    
    return OtherOS
}