import 'libphonenumber-js'
import {isValidPhoneNumber} from "libphonenumber-js";

const TelegramBreadCrumbs = ["Телег", "телег", "Тг", "тг", "Teleg", "teleg", "Tg", "tg"]
const telegramPrefix = "https://t.me/"

const VKBreadCrumbs = ["Вконтакте", "вконтакте", "ВК", "Вк", "вк", "VK", "Vk", "vk"]
const VKPrefix = "https://vk.com/"

const WhatsAppBreadCrumbs = ["WhatsApp", "whatsApp", "Whatsapp", "whatsapp", "Ватцап", "ватцап", "Вотсап", "вотсап"]
const WhatsAppPrefix = "https://wa.me/"

const possibleServices = new Map([
    [telegramPrefix, TelegramBreadCrumbs],
    [VKPrefix, VKBreadCrumbs],
    [WhatsAppPrefix, WhatsAppBreadCrumbs]
])

export function ServiceEnricher(name, url) {
    const validPhone = isValidPhoneNumber(url)

    if (validPhone && !url.includes('tel')) {
        return 'tel:' + url
    }
    for (const key of possibleServices.keys()) {
        for (const sub of possibleServices.get(key)) {
            if (name.indexOf(sub) !== -1) {
                url = url.replace("@", "")
                return key + url
            }
        }
    }

    return url
}
